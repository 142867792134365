import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from '@apollo/client';
import { JobDetailsView } from '@seek/job-details-view';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { usePropsForJobDetailsView } from 'src/components/JobDetailsPage/usePropsForJobDetailsView';
import getRecommendedBadgesForTracking from 'src/hooks/getRecommendedJobAnalyticsBadges';
import {
  createGetSolMetadataForJDP,
  trackJobDetailsLoaded,
} from 'src/hooks/trackJobDetailsLoaded';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import type { JobDetails } from 'src/store/jobdetails/types';
import { useStore } from 'src/store/react';
import { useMelwaysLocale } from 'src/utils/melwaysHelper';

import { useUpdateHomeSavedJobsCache } from '../../DashboardSavedJobs/useSavedJobs';
import { useSignedInDashboardContext } from '../../SignedInDashboardContextProvider';

const useTrackJobDetailsLoaded = (jobDetails: JobDetails) => {
  const store = useStore();
  const state = store.getState();
  const locale = useMelwaysLocale();
  const analyticsFacade = useAnalyticsFacade();
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [correlationId] = useState(uuid());
  const { selectedJob } = useSignedInDashboardContext();

  useEffect(() => {
    if (selectedJob) {
      /**
       * analyticsFacade.jobDetailsFetched needs to be called prior to calling jobDetailsLoaded.
       * Under the hood, jobDetailsFetched will set job details data (such as job title, etc) into a data layer.
       * Then jobDetailsLoaded will retrieve this from the data layer and set those data as the tracking property.
       */
      analyticsFacade.jobDetailsFetched(jobDetails);
      trackJobDetailsLoaded(
        analyticsFacade,
        {
          correlationId,
          jobDetails,
          jobListingPosition: selectedJob.analytics.position,
          jobViewOrigin: selectedJob.analytics.origin,
          jobTags: getRecommendedBadgesForTracking({
            apolloClient,
            jobId: selectedJob.id,
            locale,
          }),
          jobViewType: 'drawer',
          getSolMetadata: createGetSolMetadataForJDP({
            experiments: state.experiments,
            hash: `sol=${selectedJob.analytics.solHash}`,
            jobId: selectedJob.id,
            query: {},
          }),
        },
        state,
        apolloClient,
      );
    }
    // We only want to run this effect once per selectedJob
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);
};

export const JobDetailsViewContent = ({
  jobDetails,
}: {
  jobDetails: JobDetails;
}) => {
  const { selectedJob } = useSignedInDashboardContext();
  const { solHash, origin, position } = selectedJob?.analytics ?? {};
  const updateHomeSavedJobsCache = useUpdateHomeSavedJobsCache({
    id: jobDetails.job.id,
    advertiser: jobDetails.job.advertiser,
    location: jobDetails.job.location,
    title: jobDetails.job.title,
    listedAt: jobDetails.job.listedAt,
    products: jobDetails.job.products,
    salary: jobDetails.job.salary,
  });

  const props = usePropsForJobDetailsView({
    jobDetails,
    options: {
      origin: 'recom-homepage',
      position,
    },
    savedJobMutation: {
      create: {
        context: {
          disableEvictCache: true,
        },
        update: (cache) => {
          updateHomeSavedJobsCache.addSavedJob(cache);
        },
      },
      delete: {
        context: {
          disableEvictCache: true,
        },
        update: (cache) => {
          updateHomeSavedJobsCache.removeSavedJob(cache);
        },
      },
    },
  });
  const customProps: React.ComponentProps<typeof JobDetailsView> = {
    ...props,
    hideOpenInNewTab: false,
    job: {
      ...props.job,
      isSaved: jobDetails.personalised?.isSaved ?? false,
      applyUrl: solHash
        ? `${props.job.applyUrl}?sol=${solHash}`
        : props.job.applyUrl,
    },
    uniqueContainerValue: 'homepage-drawer',
    featureFlags: {
      ...props.featureFlags,
      stickApplyButtonOnDesktop: false,
      shouldOpenLinkInNewTab: true,
    },
    jobUrlOpts: {
      params: {
        ref: origin,
        pos: position,
        origin: true,
      },
      meta: {
        solMetadataReference: solHash,
      },
    },
  };

  useTrackJobDetailsLoaded(jobDetails);

  return <JobDetailsView {...customProps} />;
};
