import { Stack } from 'braid-design-system';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import CareerFeedGoogleAds from 'src/components/HomePage/Dashboard/SignedInDashboard/CareerFeedGoogleAds/CareerFeedGoogleAds';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import { LoadingRecommendations } from './LoadingRecommendations';
import { RecommendedJobCard } from './RecommendedJobCard';
import {
  RECOMMENDATIONS_LIMIT,
  TOP_SECTION_LIMIT,
  useHomeRecommendations,
} from './useHomeRecommendations';

const LOADING_SKELETON_COUNT = RECOMMENDATIONS_LIMIT - TOP_SECTION_LIMIT;

export const NUMBER_OF_JOBS_BEFORE_AD = 5;

const JOBS_BEFORE_AD_LIMIT = NUMBER_OF_JOBS_BEFORE_AD - TOP_SECTION_LIMIT;

export const MoreRecommendationsList = () => {
  const careerFeedAdsEnabled = useSelector(
    selectFeatureFlag('homepageGoogleAds'),
  );
  const { moreJobs } = useHomeRecommendations();

  const moreRecommendationsList = moreJobs.map((job, index) => (
    <RecommendedJobCard
      key={`recommendedJob_${job.id}`}
      jobInfo={job}
      position={TOP_SECTION_LIMIT + index + 1}
    />
  ));

  if (careerFeedAdsEnabled) {
    moreRecommendationsList.splice(
      JOBS_BEFORE_AD_LIMIT,
      0,
      <CareerFeedGoogleAds key="careerFeedGoogleAd" tagName="homepage" />,
    );
  }

  return moreRecommendationsList;
};

const MoreRecommendationsContent = () => {
  const { moreJobs, loading } = useHomeRecommendations();

  if (loading) {
    return (
      <LoadingRecommendations
        length={LOADING_SKELETON_COUNT}
        keyPrefix="moreRecs"
      />
    );
  }

  return moreJobs.length ? (
    <Stack space="small" data={{ automation: 'more-recommendations-content' }}>
      <Stack space="xsmall">
        <MoreRecommendationsList />
      </Stack>
    </Stack>
  ) : null;
};

export const MoreRecommendations = () => {
  const { loading } = useHomeRecommendations();
  return (
    <AnimatedHeight changeKeys={[loading]}>
      <MoreRecommendationsContent />
    </AnimatedHeight>
  );
};
