import { useEffect, useState } from 'react';

import { Placeholder } from 'src/components/Placeholder/Placeholder';
import { advertsIsEnabled } from 'src/config';
import { useAppConfig } from 'src/config/appConfig';
import { GooglePublisherTag } from 'src/modules/gpt-react';

const leaderboardAdSize = { width: 728, height: 90 };
const mrecAdSize = { width: 300, height: 250 };

const BREAKPOINT_OFFSET = 15;
const responsiveLeaderboardAdDesktopBreakpoint = 1320 - BREAKPOINT_OFFSET;
const responsiveMrecAdDesktopBreakpoint = 992 - BREAKPOINT_OFFSET;
const responsiveLeaderboardAdTabletBreakpoint = 800 - BREAKPOINT_OFFSET;

interface Props {
  tagName: string;
}

const CareerFeedGoogleAds = ({ tagName }: Props) => {
  const { country } = useAppConfig();
  const { GPT_ACCOUNT_ID } = useAppConfig();
  const tagId = `/${GPT_ACCOUNT_ID}/SEEK${country}/${tagName}`;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isClient) {
    return advertsIsEnabled ? (
      <GooglePublisherTag
        tagId={tagId}
        responsiveSizing={[
          {
            minViewportWidth: responsiveLeaderboardAdDesktopBreakpoint,
            sizes: [leaderboardAdSize],
          },
          {
            minViewportWidth: responsiveMrecAdDesktopBreakpoint,
            sizes: [mrecAdSize],
          },
          {
            minViewportWidth: responsiveLeaderboardAdTabletBreakpoint,
            sizes: [leaderboardAdSize],
          },
          {
            minViewportWidth: 0,
            sizes: [mrecAdSize],
          },
        ]}
      />
    ) : (
      <Placeholder
        label={`Advertisement with Tag ID: ${tagId}`}
        data-automation={`ad-${tagName}`}
      />
    );
  }

  return null;
};
export default CareerFeedGoogleAds;
