import { useMelwaysInfo } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import { Box, Inline, Text } from 'braid-design-system';

import { useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from '../.vocab';
import { otherLinks } from '../utils/other';

import { QuickSearchLink } from './QuickSearchLink';

import * as styles from '../styles.css';

export const Other = () => {
  const { language } = useMelwaysInfo();
  const zone = useMelwaysZone();
  const { t } = useTranslations(translations);
  const links = otherLinks[zone][language];

  return links && links.length > 0 ? (
    <Box display="flex">
      <Box className={styles.rowLeftColumn}>
        <Text size="small" component="h2">
          {t('Other')}
        </Text>
      </Box>
      <Inline space="small" component="ul">
        {links.map(({ keywords, location, rel }) => {
          const slugifiedKeywords = keywords
            .replace(/\s+|\//g, '-')
            ?.toLowerCase();
          return (
            <QuickSearchLink
              key={slugifiedKeywords}
              linkProps={{
                ['aria-label']: keywords,
                ['data-automation']: `quick-search-${slugifiedKeywords}-link`,
                analytics: `quick-search-${slugifiedKeywords}`,
                location,
                rel,
              }}
            >
              {keywords}
            </QuickSearchLink>
          );
        })}
      </Inline>
    </Box>
  ) : null;
};
