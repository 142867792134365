
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIjoiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6IjxMaW5rPlVwZGF0ZSB5b3VyIHByb2ZpbGU8L0xpbms%2BIG9yIHN0YXJ0IHNlYXJjaGluZyBmb3Igam9icyB0byBnZXQgcGVyc29uYWxpc2VkIGpvYiByZWNvbW1lbmRhdGlvbnMgaGVyZS4ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5QZXJiYXJ1aSBwcm9maWwgQW5kYTwvTGluaz4gYXRhdSBtdWxhaSBtZW5jYXJpIGxvd29uZ2FuIHVudHVrIG1lbmRhcGF0a2FuIHJla29tZW5kYXNpIGxvd29uZ2FuIHlhbmcgZGlwZXJzb25hbGlzYXNpIGRpIHNpbmkuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5QZXJiYXJ1aSBwcm9maWwgQW5kYTwvTGluaz4gYXRhdSBtdWxhaSBtZW5jYXJpIGxvd29uZ2FuIHVudHVrIG1lbmRhcGF0a2FuIHJla29tZW5kYXNpIGxvd29uZ2FuIHlhbmcgZGlwZXJzb25hbGlzYXNpIGRpIHNpbmkuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms%2B4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9MaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms%2B4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9MaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5QZXJiYXJ1aSBwcm9maWwgQW5kYTwvTGluaz4gYXRhdSBtdWxhaSBtZW5jYXJpIGxvd29uZ2FuIHVudHVrIG1lbmRhcGF0a2FuIHJla29tZW5kYXNpIGxvd29uZ2FuIHlhbmcgZGlwZXJzb25hbGlzYXNpIGRpIHNpbmkuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJCZXJkYXNhcmthbiBwcm9maWwsIHRhbXBpbGFuIHBla2VyamFhbiwgZGFuIGFwbGlrYXNpIEFuZGEuIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiI8TGluaz5QZXJiYXJ1aSBwcm9maWwgQW5kYTwvTGluaz4gYXRhdSBtdWxhaSBtZW5jYXJpIGxvd29uZ2FuIHVudHVrIG1lbmRhcGF0a2FuIHJla29tZW5kYXNpIGxvd29uZ2FuIHlhbmcgZGlwZXJzb25hbGlzYXNpIGRpIHNpbmkuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms%2B4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9MaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiLguILguLbguYnguJnguK3guKLguLnguYjguIHguLHguJrguYLguJvguKPguYTguJ%2FguKXguYwg4Lih4Li44Lih4Lih4Lit4LiH4LiH4Liy4LiZIOC5geC4peC4sOC5g%2BC4muC4quC4oeC4seC4hOC4o%2BC4guC4reC4h%2BC4hOC4uOC4kyIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms%2B4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9MaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJb4bqexIPEg8SDxaHhur3hur3hur3GjCDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxqXFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0sIMS1w7bDtsO2w58g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDEg8SDxIPguIHguLXguYnGjCDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhLl0iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6Ils8TGluaz7HmcalxozEg8SD4bmv4bq94bq9IMO9w73DtsO2x5rHmsWZIMalxZnDtsO2xpLDrMOsxprhur3hur08L0xpbms%2BIMO2w7bDtsWZIMWh4bmvxIPEg8SDxZnhua8gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipw6zDrMOs4LiB4Li14LmJxKMgxpLDtsO2w7bFmSDEtcO2w7bDtsOfxaEg4bmvw7bDtsO2IMSj4bq94bq94bq94bmvIMal4bq94bq94bq9xZnFocO2w7bDtuC4geC4teC5icSDxIPEg8aaw6zDrMOsxaHhur3hur3hur3GjCDEtcO2w7bDtsOfIMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjMSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIOG4qeG6veG6veG6vcWZ4bq94bq94bq9Ll0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4iOiJb4bqexIPEg8SDxaHhur3hur3hur3GjCDDtsO2w7bguIHguLXguYkgw73DvcO9w7bDtsO2x5rHmseaxZkgxqXFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0sIMS1w7bDtsO2w58g4bm9w6zDrMOs4bq94bq94bq9xbXFoSDEg8SDxIPguIHguLXguYnGjCDEg8SDxIPGpcalxprDrMOsw6zDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhLl0iLCJVcGRhdGUgeW91ciBwcm9maWxlIG9yIHN0YXJ0IHNlYXJjaGluZyI6Ils8TGluaz7HmcalxozEg8SD4bmv4bq94bq9IMO9w73DtsO2x5rHmsWZIMalxZnDtsO2xpLDrMOsxprhur3hur08L0xpbms%2BIMO2w7bDtsWZIMWh4bmvxIPEg8SDxZnhua8gxaHhur3hur3hur3Eg8SDxIPFmcOn4bipw6zDrMOs4LiB4Li14LmJxKMgxpLDtsO2w7bFmSDEtcO2w7bDtsOfxaEg4bmvw7bDtsO2IMSj4bq94bq94bq94bmvIMal4bq94bq94bq9xZnFocO2w7bDtuC4geC4teC5icSDxIPEg8aaw6zDrMOsxaHhur3hur3hur3GjCDEtcO2w7bDtsOfIMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjMSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhIOG4qeG6veG6veG6vcWZ4bq94bq94bq9Ll0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    