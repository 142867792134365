import type { JobViewType } from '@seek/seek-jobs-analytics';
import { useCallback, useMemo } from 'react';

import {
  useUpdateHomeSavedJobsCache,
  type SavedJob,
} from 'src/components/HomePage/Dashboard/SignedInDashboard/DashboardSavedJobs/useSavedJobs';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import { selectSessionId } from 'src/store/selectors';
import { useMelwaysLocale, useMelwaysZone } from 'src/utils/melwaysHelper';

import { useSolHash } from '../useSolHash';

import useSaveJobMutation, {
  type UseSaveJobMutationProps,
} from './utils/useSaveJobMutation';
import type { UseToggleSaveJobProps } from './utils/useToggleSavedJob';

interface UseSaveJobHomeRecsProps
  extends Pick<UseToggleSaveJobProps, 'solMetadataReference'> {
  job: SavedJob;
  isSaved?: boolean;
  analytics: {
    jobViewOriginQuery: string;
    jobListingPosition: number;
    jobViewType: JobViewType;
    linkPosition: string;
  };
}

export const useSaveJobHomeRecs = ({
  job,
  solMetadataReference,
  isSaved,
  analytics,
}: UseSaveJobHomeRecsProps) => {
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();
  const sessionId = useSelector(selectSessionId);
  const updateHomeSavedJobsCache = useUpdateHomeSavedJobsCache(job);
  const analyticsFacade = useAnalyticsFacade();
  const { jobViewOriginQuery, jobListingPosition, jobViewType, linkPosition } =
    analytics;
  const [, solMetadata] = useSolHash({
    id: job.id,
    solMetadataReference,
  });

  const { create: createSavedJob, delete: deleteSavedJob } = useSaveJobMutation(
    job.id,
    {
      create: {
        context: {
          disableEvictCache: true,
        },
        update: (cache) => {
          updateHomeSavedJobsCache.addSavedJob(cache);
        },
      },
      delete: {
        context: {
          disableEvictCache: true,
        },
        update: (cache) => {
          updateHomeSavedJobsCache.removeSavedJob(cache);
        },
      },
    },
  );

  const eventCaptureData = useMemo(
    () => ({
      channel: 'web',
      view: 'recommendationsList',
      eventCaptureSessionId: sessionId ?? '',
    }),
    [sessionId],
  );

  const commonInput = useMemo(
    () => ({
      id: `${job.id}`,
      eventCaptureData,
    }),
    [job.id, eventCaptureData],
  );

  const createSavedJobOptions: UseSaveJobMutationProps['create'] = useMemo(
    () => ({
      variables: {
        input: {
          ...commonInput,
          zone,
        },
        locale,
      },
    }),
    [commonInput, locale, zone],
  );

  const deleteSavedJobOptions: UseSaveJobMutationProps['delete'] = useMemo(
    () => ({
      variables: {
        input: commonInput,
        locale,
      },
    }),
    [commonInput, locale],
  );

  const toggleSavedJob = useCallback(() => {
    if (isSaved) {
      deleteSavedJob(deleteSavedJobOptions);

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobRemoved(
          { solMetadata },
          {
            jobId: job.id,
            jobViewOriginQuery,
            jobListingPosition,
            jobViewType,
            linkPosition,
          },
        );
      }
    } else {
      createSavedJob(createSavedJobOptions);

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobCreated(
          { solMetadata },
          {
            jobId: job.id,
            jobViewOriginQuery,
            jobListingPosition,
            jobViewType,
            linkPosition,
          },
        );
      }
    }
  }, [
    isSaved,
    deleteSavedJob,
    deleteSavedJobOptions,
    analyticsFacade,
    solMetadata,
    job.id,
    createSavedJob,
    createSavedJobOptions,
    jobViewOriginQuery,
    jobListingPosition,
    jobViewType,
    linkPosition,
  ]);

  return { toggleSavedJob };
};
