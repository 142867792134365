import { useMelwaysInfo } from '@seek/melways-react';

import type { SafetyHelmetProps } from 'src/components/SafetyHelmet/SafetyHelmet';

import { generateMetaDescription } from '../utils';

import { jobstreetAlternateLinks } from './alternateLinks';
import { useDescriptionContent } from './description/content';

export const useHomePageHead = () => {
  const { brand } = useMelwaysInfo();
  const descriptionContent = useDescriptionContent();
  const tags: SafetyHelmetProps = {
    script: [],
    meta: generateMetaDescription(descriptionContent),
    ...(brand === 'jobstreet' ? { link: jobstreetAlternateLinks } : {}),
  };

  return tags;
};
