import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.16_@types+node@22.10.10_babel-plugin-macros@3.1.0_terser@_aoa4wo57hpe4tpbuscfjiajida/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2SwXKCMBBA73xFjuUQB0FR8NI/6cSwQiokaYhC7fjvHVKZmgBi771B9m2y+3YXb0sRtHV+ydCXh9ChhBZnTAHVTPAUnYl6wbhn1v7Ou3qL/hdMiiRZxniOtZAuH/u7O2AvtBaVy2wspoSDdomtRSiWFwMksQs7mMIKGEOJua1iHI/H9ybesEwXboia0J7QY67EiWeYVSQHl1q6VM0ukCIqzqCciAIJRKeIi9unE5eiZmNzCO12c9PuXR4VpVBuUmAnFXPDi2yeGb4pmAZcS0KhK7tRRFrUu6HuNqZrvDxVfGcdkxRFQSBb+5SmKIxX7unmtzDJjsEHCXwbiOeA7RyQuIDT+9EV3I+Ua8L4xNDwZ4oocN1N/eq9VpAxgmqqADgiPEMvFWnxbc/WnQzfvOKonJQ5pXNK6EOlke8iE1Kf0PpQ7A9ynRDCeC9ks/oXYglJkvCvQuYvXYbP7p0SzZjjJBp1HK3iJxzH846HyMDxEBk4jnvH33ByQartBgAA\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.16_@types+node@22.10.10_babel-plugin-macros@3.1.0_terser@_aoa4wo57hpe4tpbuscfjiajida/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var centerImage = '_1o0xsgzk';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';