import type { JobTag } from '@seek/chalice-types';
import { DotSeparator } from '@seek/discover-ui';
import { useMelwaysLink } from '@seek/melways-react';
import {
  Box,
  Column,
  Columns,
  Link,
  List,
  Stack,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import { useCallback, type ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import striptags from 'striptags';

import { LazyLoadedImg } from 'src/components/HomePage/LazyLoadedImg/LazyLoadedImg';
import useSalaryDisplayLabel from 'src/hooks/useSalaryDisplayLabel/useSalaryDisplayLabel';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { selectFeatureFlag } from 'src/store/selectors';
import { getSearchParams } from 'src/utils/urlParams';

import { useSignedInDashboardContext } from '../../SignedInDashboardContextProvider';

import { JobBadges } from './components/JobBadges/JobBadges';
import { JobLabels } from './components/JobLabels';
import SaveJobButton from './components/SaveJobButton/SaveJobButton';
import { useRecommendationBadges } from './hooks/useRecommendationBadges';

import * as styles from './JobCard.css';

export type Job = {
  solMetadata?: any;
  id: string;
  title: string;
  advertiserName: string;
  salaryLabel?: string;
  salaryCurrencyLabel?: string;
  locationLabel: string;
  listingDateLabel?: string;
  srcLogo?: string;
  isExpired?: boolean;
  isSaved?: boolean;
  tags?: JobTag[];
  bullets?: string[];
};

interface JobCardProps {
  jobInfo: Job;
  analytics: {
    linkName: string;
    linkSection: string;
    position: number;
    solHash?: string;
    viewOrigin: 'saved-homepage' | 'recom-homepage';
  };
  testAutomation?: {
    jobCardLink?: string;
  };
  onSaveJobButtonClick?: ComponentProps<typeof SaveJobButton>['onClick'];
}

export const HOMEPAGE_JOB_CARD_LOGO_HEIGHT = 65;
const HOMEPAGE_JOB_CARD_LOGO_MIN_WIDTH = 65;
export const HOMEPAGE_JOB_CARD_LOGO_MAX_WIDTH = 100;

const constructPath = (
  path: string,
  location?: {
    queryParams?: Record<string, string>;
    solHash?: string;
  },
) => {
  const searchParams = location?.queryParams
    ? `?${getSearchParams(location.queryParams).toString()}`
    : '';

  const solHash = location?.solHash ? `#sol=${location.solHash}` : '';

  return `${path}${searchParams}${solHash}`;
};

const CARD_PADDING = 'medium';

const JobCard = ({
  jobInfo,
  onSaveJobButtonClick,
  analytics: { linkName, linkSection, position, solHash, viewOrigin },
  testAutomation,
}: JobCardProps) => {
  const {
    advertiserName,
    id,
    isExpired,
    isSaved = false,
    listingDateLabel,
    locationLabel,
    salaryCurrencyLabel,
    salaryLabel,
    srcLogo,
    tags,
    title,
    bullets,
  } = jobInfo;

  const analyticsFacade = useAnalyticsFacade();

  const { badges, labels, viewedLabel } = useRecommendationBadges(
    tags,
    isExpired,
  );

  const salaryDisplayLabel = useSalaryDisplayLabel({
    currencyLabel: salaryCurrencyLabel,
    salary: salaryLabel,
  });

  const jobUrl = useMelwaysLink({
    path: constructPath(isExpired ? `/expiredjob/${id}` : `/job/${id}`, {
      queryParams: {
        ref: viewOrigin,
        pos: String(position),
      },
      solHash,
    }),
  });

  const { setSelectedJob } = useSignedInDashboardContext();
  const homepageJobCardDensity = useSelector(
    selectFeatureFlag('homepageJobCardDensity'),
  );
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  const onJobCardClick = useCallback(
    (event: React.MouseEvent) => {
      analyticsFacade.linkClicked({
        href: jobUrl,
        linkName,
        linkContext: {
          jobId: id,
          linkPosition: String(position),
          linkSection,
        },
      });

      if (!isMobile) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedJob({
          id,
          analytics: {
            origin: viewOrigin,
            position,
            solHash,
          },
        });
      }
    },
    [
      analyticsFacade,
      jobUrl,
      linkName,
      id,
      isMobile,
      position,
      linkSection,
      setSelectedJob,
      solHash,
      viewOrigin,
    ],
  );

  return (
    <Box
      aria-label={striptags(title)}
      position="relative"
      className={[
        styles.jobCardBase,
        styles.hoveredJobCard,
        styles.focusedJobCard,
      ]}
      padding={CARD_PADDING}
    >
      <Link
        href={jobUrl}
        className={styles.jobCardLink}
        onClickCapture={onJobCardClick}
        data={
          testAutomation?.jobCardLink
            ? { automation: testAutomation.jobCardLink }
            : {}
        }
      />
      <Stack space="small">
        <Columns
          space={{ tablet: 'small', mobile: srcLogo ? 'small' : 'none' }}
          collapseBelow="tablet"
          reverse
        >
          <Column width="content">
            <Box
              style={{
                // Removing height to preserve logo spacing above mobile breakpoint
                height: srcLogo ? HOMEPAGE_JOB_CARD_LOGO_HEIGHT : 0,
                maxWidth: HOMEPAGE_JOB_CARD_LOGO_MAX_WIDTH,
                minWidth: HOMEPAGE_JOB_CARD_LOGO_MIN_WIDTH,
              }}
            >
              {srcLogo ? (
                <Box
                  display="flex"
                  height="full"
                  data-automation="job-card-image"
                >
                  <LazyLoadedImg src={srcLogo} alt="" />
                </Box>
              ) : null}
            </Box>
          </Column>
          <Column>
            <Stack space="small">
              <Stack space="xsmall">
                <Text size="large" weight="medium">
                  <Box
                    className={{ [styles.markVisited]: Boolean(viewedLabel) }}
                    component="span"
                    data={{
                      automation: 'jobTitle',
                    }}
                  >
                    {title}
                  </Box>
                </Text>
                <Text
                  tone="secondary"
                  data={{
                    automation: 'jobAdvertiser',
                  }}
                >
                  {advertiserName}
                </Text>
              </Stack>
              <Stack space="small">
                <JobBadges badges={badges} />
                <Stack space="xsmall">
                  {locationLabel ? (
                    <Text size="small" tone="secondary">
                      {locationLabel}
                    </Text>
                  ) : null}
                  {salaryDisplayLabel ? (
                    <Text size="small" tone="secondary">
                      {salaryDisplayLabel}
                    </Text>
                  ) : null}
                </Stack>
              </Stack>
              {homepageJobCardDensity && bullets?.length ? (
                <List size="small" space="xsmall">
                  {bullets.map((bullet, i) => (
                    <Text key={i} size="xsmall" tone="secondary">
                      {bullet}
                    </Text>
                  ))}
                </List>
              ) : null}
            </Stack>
          </Column>
        </Columns>
        {listingDateLabel ? (
          <Text size="xsmall" tone="secondary">
            {viewedLabel ? (
              <DotSeparator content={[listingDateLabel, viewedLabel]} />
            ) : (
              listingDateLabel
            )}
          </Text>
        ) : null}
        <JobLabels labels={labels} />
        {onSaveJobButtonClick && (
          <Box
            position="absolute"
            right={0}
            bottom={0}
            paddingRight={CARD_PADDING}
            paddingBottom={CARD_PADDING}
          >
            <SaveJobButton
              id={`recs-homepage-${isSaved ? 'unsave' : 'save'}-job-${id}`}
              isSaved={isSaved}
              onClick={onSaveJobButtonClick}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default JobCard;
