import { useMelwaysLink } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import { Box, ButtonLink, Tiles } from 'braid-design-system';
import { useCallback, type ReactNode } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import { getViewAllLinkData } from '../SharedComponent/ViewAllLink/ViewAllLink';

import translations from './.vocab';

const MyActivityButton = ({
  section,
  children,
}: {
  section: 'savedJobs' | 'savedSearches';
  children: ReactNode;
}) => {
  const {
    viewAllLinkPath,
    analytics: { linkName, linkSection },
  } = getViewAllLinkData(section);
  const viewAllLinkHref = useMelwaysLink({ path: viewAllLinkPath });
  const analytics = useAnalyticsFacade();

  const textLinkOnClick = useCallback(() => {
    analytics.linkClicked({
      linkContext: { linkSection },
      linkName,
      href: viewAllLinkHref,
    });
  }, [analytics, linkName, linkSection, viewAllLinkHref]);

  return (
    <ButtonLink
      variant="soft"
      size="small"
      tone="neutral"
      onClick={textLinkOnClick}
      href={viewAllLinkHref}
    >
      {children}
    </ButtonLink>
  );
};

export const MyActivityTiles = () => {
  const { t } = useTranslations(translations);

  return (
    <Box display={{ mobile: 'block', tablet: 'flex', desktop: 'none' }}>
      <Tiles columns={2} space="xsmall">
        <MyActivityButton section="savedSearches">
          {t('Saved searches')}
        </MyActivityButton>
        <MyActivityButton section="savedJobs">
          {t('Saved jobs')}
        </MyActivityButton>
      </Tiles>
    </Box>
  );
};
