import { useTranslations } from '@vocab/react';
import { Column, Columns, HiddenVisually, Stack } from 'braid-design-system';
import { memo, useEffect } from 'react';

import translations from './.vocab';
import { MoreRecommendations } from './DashboardRecommendations/MoreRecommendations';
import { TopRecommendations } from './DashboardRecommendations/TopRecommendations';
import SavedSearches from './DashboardSaveSearches/SavedSearches';
import SavedJobs from './DashboardSavedJobs/SavedJobs';
import { MyActivityTiles } from './MyActivityTiles/MyActivityTiles';
import { JobDetailsViewDrawer } from './SharedComponent/JobDetailsViewDrawer/JobDetailsViewDrawer';
import { SignedInDashboardContextProvider } from './SignedInDashboardContextProvider';

const SignedInDashboard = () => {
  const { t } = useTranslations(translations);

  useEffect(() => {
    window.hj ||= function () {
      // eslint-disable-next-line prefer-rest-params
      (hj.q = hj.q || []).push(arguments);
    };

    window.hj('event', 'homepage_layout_refresh_experiment');
  }, []);

  return (
    <SignedInDashboardContextProvider>
      <HiddenVisually>
        <h1>{t('Dashboard')}</h1>
      </HiddenVisually>

      <Columns space="xlarge">
        <Column>
          <Stack space="medium">
            <MyActivityTiles />
            <Stack space="xsmall">
              <TopRecommendations />
              <MoreRecommendations />
            </Stack>
          </Stack>
        </Column>
        <Column width="2/5" hideBelow="desktop">
          <Stack space="large">
            <SavedSearches />
            <SavedJobs />
          </Stack>
        </Column>
      </Columns>
      <JobDetailsViewDrawer />
    </SignedInDashboardContextProvider>
  );
};

export default memo(SignedInDashboard);
