import type { Zone } from '@seek/audience-zones';
import { useMelwaysInfo } from '@seek/melways-react';
import type { Country, Language } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { stringify } from 'query-string';

import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import { useMelwaysCountry, useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from '../.vocab';
import type { BannerProps } from '../HomePageBanner';

// @ts-expect-error
import candidateImage from './GenericAsiaCandidateBanner.png';
// @ts-expect-error
import hirerImage from './GenericAsiaHirerBanner.png';
// @ts-expect-error
import jobstreetJuicySalaryNews from './Jobstreet_JuicySalaryNews.png';

const useGenericAsiaMarketingContent = (): [BannerProps, BannerProps] => {
  const { t } = useTranslations(translations);

  const country = useMelwaysCountry();
  const zone = useMelwaysZone();
  const { brand, language } = useMelwaysInfo();

  return [
    // Candidate
    {
      text: {
        title:
          brand === 'jobstreet'
            ? t('All the juicy salary news in your industry')
            : t('"Hello" to a better career and salary'),
        inverted: true,
      },
      background: {
        color: '#E60278',
        imageUrl:
          brand === 'jobstreet' ? jobstreetJuicySalaryNews : candidateImage,
        centerImage: brand === 'jobstreet',
      },
      cta: {
        label:
          brand === 'jobstreet'
            ? t('Go to Community')
            : t('Get tips and tools'),
        variant: 'solid',
        tone: 'neutral',
        inverted: true,
        linkContext: getLinkContext('candidate'),
      },
      location: getCTALocation(zone, language, country, 'candidate'),
    },
    // Hirer
    {
      text: {
        title: t('"Hello" to better talent matches'),
      },
      background: {
        color: '#E6EAF2',
        imageUrl: hirerImage,
      },
      cta: {
        label: t('Hire for FREE'),
        variant: 'solid',
        tone: 'brandAccent',
        linkContext: getLinkContext('hirer'),
      },
      location: getCTALocation(zone, language, country, 'hirer'),
    },
  ];
};

const candidateCTALocation: Partial<
  Record<Country, Partial<Record<Language, string>>>
> = {
  hk: {
    en: 'https://hk.jobsdb.com/career-advice/',
  },
  th: {
    en: 'https://th.jobsdb.com/career-advice/',
    th: 'https://th.jobsdb.com/th/career-advice/',
  },
  id: {
    en: 'https://id.jobstreet.com/community',
    id: 'https://id.jobstreet.com/id/community',
  },
  my: {
    en: 'https://my.jobstreet.com/community',
  },
  ph: {
    en: 'https://ph.jobstreet.com/community',
  },
  sg: {
    en: 'https://sg.jobstreet.com/community',
  },
};

const hirerCTALocation: Partial<
  Record<Country, Partial<Record<Language, string>>>
> = {
  hk: {
    en: 'https://hk.employer.seek.com/',
  },
  th: {
    en: 'https://th.employer.seek.com/',
    th: 'https://th.employer.seek.com/th/',
  },
  id: {
    en: 'https://id.employer.seek.com/',
    id: 'https://id.employer.seek.com/id/',
  },
  my: {
    en: 'https://my.employer.seek.com/',
  },
  ph: {
    en: 'https://ph.employer.seek.com/',
  },
  sg: {
    en: 'https://sg.employer.seek.com/',
  },
};

const getCTALocation = (
  zone: Zone,
  language: Language,
  country: Country,
  bannerType: string,
): string | undefined => {
  if (!zone || !language) {
    return undefined;
  }

  const utm_campaign = country;
  const utm_medium = isMobileUserAgent() ? 'mobile_web' : 'desktop';
  const utm_source = 'seek';

  let href;
  let queryParams;
  switch (bannerType) {
    case 'candidate':
      href = candidateCTALocation[country]?.[language] || '';

      queryParams = stringify({
        tracking: `CTA-WEB-HomepageMarketingBanner-${zone}`,
        utm_content: 'homepage_banner_1',
        utm_source,
        utm_campaign,
        utm_medium,
      });

      break;

    case 'hirer':
      href = hirerCTALocation[country]?.[language] || '';

      queryParams = stringify({
        tracking: `ILC-WEB-HomepageMarketingBanner-${zone}`,
        utm_content: 'homepage_banner_2',
        utm_source,
        utm_campaign,
        utm_medium,
      });

      break;
  }

  return href ? `${href}?${queryParams}` : undefined;
};

const getLinkContext = (bannerType: string) => ({
  linkAction:
    bannerType === 'candidate' ? 'open career advice' : 'open hirer site',
  linkText: bannerType === 'candidate' ? 'Get tips and tools' : 'Hire for FREE',
});

export default useGenericAsiaMarketingContent;
